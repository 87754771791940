import { get } from '@/lib/api'

const getBeneficiaries = async () => {
  const { error, response } = await get('/beneficiaries')

  if (error) {
    return { error }
  }

  return { response }
}

export default {
  getBeneficiaries
}
