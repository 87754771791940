import beneficiariesApi from '@/api/beneficiaries'

const getInitialState = () => ({
  list: []
})

const state = getInitialState()

const mutations = {
  SET_LIST (state, value) {
    state.list = value
  }
}

const actions = {
  async getBeneficiaries ({ commit }) {
    console.log('here')
    const { response, error } = await beneficiariesApi.getBeneficiaries()

    if (error) {
      console.error('Api error', error)
    }

    commit('SET_LIST', response)
  }
}

const getters = {
  beneficiaries (state) {
    return state.list
  }
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
}
